<div class="w-100 h-100 d-flex flex-column">
  <app-header-space-between *ngIf="headerThreeColumns; else simpleHeader">
    <ng-content leftColumn select="[leftColumn]"></ng-content>
    <ng-content centerColumn select="[centerColumn]"></ng-content>
    <ng-content rightColumn select="[rightColumn]"></ng-content>
  </app-header-space-between>
  <ng-template #simpleHeader>
    <app-header>
      <ng-content headerExtension select="[headerExtension]"></ng-content>
      <ng-content headerButtons select="[headerButtons]"></ng-content>
    </app-header>
  </ng-template>
  <div class="
    overflow-auto
    box-sizing-border-box
    flex-fill
    d-flex
    flex-column
    gap-16
  ">
    <ng-content select="[contentHeader]"></ng-content>
    <div class="overflow-auto flex-fill d-flex flex-column">
      <ng-content select="[contentMain]"></ng-content>
    </div>
  </div>
</div>
