import {
  Component,
  Input
} from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/auth.service';
import { TitleService } from 'src/app/services/title.service';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';
import { SessionService } from 'src/app/core/session.service';

@Component({
  selector: 'app-header-space-between',
  templateUrl: './header-space-between.component.html',
  styleUrls: ['./header-space-between.component.scss']
})
export class HeaderSpaceBetweenComponent {
  title$: Observable<string>;

  @Input() threeColumns = false;

  constructor(
    public authService: AuthService,
    public sidebarService: SidebarService,
    public titleService: TitleService,
    public sessionService: SessionService,
  ) {}
}
