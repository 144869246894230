<div class="small-form-field">
  <mat-form-field
    class="w-100 form-single-select"
    appearance="outline"
  >
    <mat-label>{{ label || 'Selecionar' }}</mat-label>
    <input
      matInput
      autocomplete="off"
      name="new-search"
      [formControl]="search"
      [placeholder]="placeholder || 'Buscar'"
      [matAutocomplete]="autocomplete"
      (keyup.enter)="searchOnKeyEnter()"
    />
    <div
      matSuffix
      class="d-flex align-items-center justify-content-center form-single-select__boxicon"
      [ngClass]="{ 'form-single-select__boxicon--disabled': disabled }"
    >
      <mat-progress-spinner
        class="form-single-select__spinner"
        diameter="20"
        mode="indeterminate"
        *ngIf="loaderSearch; else icon"
      ></mat-progress-spinner>
      <ng-template #icon>
        <mat-icon
          matSuffix
          class="form-single-select__hint"
          *ngIf="!search.value?.length; else searchIcon"
        >arrow_drop_down</mat-icon>
        <ng-template #searchIcon>
          <mat-icon
            matSuffix
            class="form-single-select__search"
            *ngIf="search.value?.length"
          >search</mat-icon>
        </ng-template>
      </ng-template>
    </div>
    <mat-autocomplete
      class="overflow-hidden"
      #autocomplete="matAutocomplete"
      [displayWith]="getDisplayValue"
      (opened)="autocompleteOpened()"
      (optionSelected)="selected.setValue($event.option.value)"
    >
      <div
        class="h-100 position-relative d-flex"
        [ngClass]="{ 'form-single-select__splited': withDescriptions }"
      >
        <div class="overflow-auto form-single-select__scroll--main">
          <div class="form-single-select__scroll--content">
            <ng-container *ngFor="let data of filteredOptions">
              <mat-optgroup
                [label]="data.name"
                *ngIf="!!data.children; else withoutChildren"
              >
                <mat-option
                  *ngFor="let child of data.children"
                  [value]="child"
                  [ngClass]="{
                    'form-single-select__selected':
                      child.id === selected.value?.id
                  }"
                  (mouseenter)="description = child?.description"
                  (mouseleave)="description = undefined"
                >{{ child.name }}</mat-option>
              </mat-optgroup>
              <ng-template #withoutChildren>
                <mat-option
                  [value]="data"
                  [ngStyle]="{ 'padding-left': 16 * data.level + 'px' }"
                  [ngClass]="{
                    'form-single-select__selected': data.id === selected.value?.id,
                    'form-single-select__bolder': data.bolder
                  }"
                  [matTooltip]="data.tooltip"
                  [matTooltipDisabled]="!data.sufix"
                  [matTooltipShowDelay]="1000"
                  [matTooltipPosition]="'above'"
                  (mouseenter)="description = data?.description"
                  (mouseleave)="description = undefined"
                >
                  {{ data.name }}
                  <ng-container *ngTemplateOutlet="
                    data.template;
                    context: { $implicit: data.sufix }
                  "></ng-container>
                </mat-option>
              </ng-template>
            </ng-container>
            <button
              mat-flat-button
              class="mb-2 w-100 font-weight-500 form-single-select__button"
              *ngIf="!!endButtonText?.length"
              (click)="onChange(endButtonValue)"
            >
              <mat-icon
                class="mr-2"
                *ngIf="!!endButtonIcon"
              >{{ endButtonIcon }}</mat-icon>
              {{ endButtonText }}
            </button>
            <mat-option *ngIf="loaderScroll">
              <mat-progress-spinner
                class="mx-auto"
                mode="indeterminate"
                diameter="24"
              ></mat-progress-spinner>
            </mat-option>
          </div>
        </div>
        <div
          class="pl-2 form-single-select__scroll--description"
          *ngIf="withDescriptions"
        >
          <div class="h-100 position-relative d-flex">
            <div class="overflow-auto">
              <p
                class="py-2 pr-2 m-0"
                [innerHTML]="description || selected.value?.description"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </mat-autocomplete>
    <mat-error *ngIf="!!errorText?.length">{{ errorText }}</mat-error>
  </mat-form-field>
</div>
