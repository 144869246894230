<header class="header">
  <div class="w-100 d-flex align-items-center gap-16">
    <div
      class="sm-hide"
      *ngIf="sessionService.isLoggedIn$ | async"
    >
      <button mat-icon-button (click)="sidebarService.toggleMenu()">
        <mat-icon class="header__menu">menu</mat-icon>
      </button>
    </div>
    <div class="w-100 d-flex justify-content-between">
      <ng-content select="[leftColumn]"></ng-content>
      <h2 class="header__title">
        <ng-content select="[centerColumn]"></ng-content>
      </h2>
      <ng-content select="[rightColumn]"></ng-content>
    </div>
  </div>
</header>
