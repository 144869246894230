import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HeaderModule} from '../header/header.module';
import {HeaderSpaceBetweenModule} from '../header-space-between/header-space-between.module';

import {ContainerHeaderComponent} from './container-header.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    HeaderSpaceBetweenModule
  ],
  declarations: [
    ContainerHeaderComponent
  ],
  exports: [
    ContainerHeaderComponent
  ]
})

export class ContainerHeaderModule {}
