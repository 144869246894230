import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  Validators
} from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';

import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter
} from 'rxjs/operators';

import { ISingleSelect } from './entities/single-select.interfaces';

@Component({
  selector: 'app-form-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FormSingleSelectComponent
    }
  ]
})
export class FormSingleSelectComponent implements
  OnInit,
  OnDestroy,
  MatFormFieldControl<any>,
  ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() withDescriptions: boolean;
  @Input() searchExternal: boolean;
  @Input() endButtonText: string;
  @Input() endButtonIcon: string;
  @Input() endButtonValue: any;
  @Input() errorText: string;

  @Output() onSearch = new EventEmitter<string>();
  @Output() onScroll = new EventEmitter<void>();

  @ViewChild('searchInput') private searchInput: ElementRef;
  @ViewChild(MatAutocomplete) private autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) private autocompleteTrigger: MatAutocompleteTrigger;

  id: string;
  focused: boolean;
  shouldLabelFloat: boolean;
  errorState: boolean;
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;
  filteredOptions: ISingleSelect[];

  stateChanges = new Subject<void>();
  selected = new UntypedFormControl();
  search = new UntypedFormControl();
  description: string;

  private _dataSource: ISingleSelect[];
  private _disabled = false;
  private _loaderSearch = false;
  private _loaderScroll = false;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get dataSource(): ISingleSelect[] {
    return this._dataSource;
  }
  set dataSource(dataSource: ISingleSelect[]) {
    this._dataSource = dataSource;
    this.filteredOptions = dataSource;
  };

  @Input()
  set value(value: string | ISingleSelect) {
    if (value !== null) {
      this.selected.setValue(value, { emitEvent: false });
      this.search.setValue(value);
    }

    this.stateChanges.next();
  }

  @Input()
  set required(value: undefined | boolean) {
    const required = coerceBooleanProperty(value);

    if (required) {
      this.search.setValidators(Validators.required);
    } else {
      this.search.clearValidators();
    }

    this.search.updateValueAndValidity();
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: undefined | boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.search[this.disabled ? 'disable' : 'enable']();
    this.stateChanges.next();
  }

  @Input()
  set forceTouch(value: undefined | boolean) {
    const forceTouch = coerceBooleanProperty(value);

    if (forceTouch) {
      this.search.markAsTouched();
    } else {
      this.search.markAsPristine();
      this.search.markAsUntouched();
    }

    this.stateChanges.next();
  }

  @Input()
  get loaderSearch(): boolean {
    return this._loaderSearch;
  }
  set loaderSearch(value: boolean) {
    this._loaderSearch = value;

    if (!this.loaderSearch) {
      this.searchInput?.nativeElement.focus();
    }

    this.stateChanges.next();
  }

  @Input()
  get loaderScroll(): boolean {
    return this._loaderScroll;
  }
  set loaderScroll(value: undefined | boolean) {
    this._loaderScroll = coerceBooleanProperty(value);

    if (this._loaderScroll && this.autocomplete?.isOpen) {
      const scroller = this.getScroller(),
       content = this.getScrollerContent();

      setTimeout(() => scroller.scrollTop = content.scrollHeight);
    }

    this.stateChanges.next();
  }

  ngOnInit() {
    this.selected.valueChanges
      .subscribe((value) => this.onChange(value));

    this.search.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((value: string) => (
          typeof value === 'string' &&
          (this.selected.value?.id !== value)
        ))
      ).subscribe((value: string) => {
        this.searchExternal ?
          this.onSearch.next(value) :
          this.filteredOptions = this.dataSource
            .filter((item: ISingleSelect) => {
              const search = this.normalize(value);
              const name = this.normalize(item?.name);
              const description = this.normalize(item?.description);

              return name?.includes(search) || description?.includes(search);
            });
      });
  }

  private normalize(value: string): string {
    if (!value) {
      return '';
    }

    return value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  get empty(): boolean {
    return !this.selected;
  }

  searchOnKeyEnter(): void {
    if (this.autocomplete.isOpen) {
      this.autocompleteTrigger.closePanel();
    } else {
      this.autocompleteTrigger.openPanel();
    }
  }

  getDisplayValue(item: ISingleSelect): string {
    return item?.name || '';
  }

  autocompleteOpened(): void {
    const scroller = this.getScroller();

    scroller.addEventListener('scroll', () => {
      const content = this.getScrollerContent(),
       scrollerRect = scroller.getBoundingClientRect(),
       contentRect = content.getBoundingClientRect();

      if (
        !this.loaderScroll &&
        scrollerRect.bottom === contentRect.bottom
      ) {
        this.onScroll.next();
      }
    });
  }

  writeValue(value: string | ISingleSelect): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}
  onChange(value: string | ISingleSelect): void {}
  onTouch(): void {}

  private getScroller(): Element {
    const elementClass = 'form-single-select__scroll--main';
    return document.getElementsByClassName(elementClass)[0];
  }

  private getScrollerContent(): Element {
    const elementClass = 'form-single-select__scroll--content';
    return document.getElementsByClassName(elementClass)[0];
  }
}
